import React, { FC } from "react";
import { graphql } from "gatsby";
import { getSrc } from "gatsby-plugin-image";

import { MDX, NewsHero, NewsHeroProps } from "components";
import {
  Contact,
  NewsHeaderMenu,
  RecommendedNews,
  SEO,
  SEOProps,
} from "containers";
import { DefaultLayout, DefaultLayoutProps } from "layouts";

import { NewsTemplateProps } from "./types";

export const NewsTemplate: FC<NewsTemplateProps> = ({
  data: {
    news: {
      body,
      excerpt,
      frontmatter: {
        // author,
        brand,
        category,
        contact,
        // dateCreated,
        // dateUpdated,
        description,
        hero,
        header,
        image,
        layout,
        main,
        mdx,
        seo,
        showAuthor = false,
        showFooter = true,
        showHeader = true,
        showRecommended = true,
        // tags,
        title,
      },
    },
  },
  location,
  pageContext: { next, prev },
}) => (
  <>
    <SEO
      title={seo?.title || title}
      description={
        seo?.description
          ? seo?.description
          : description
          ? description
          : excerpt
      }
      image={image && getSrc(image.childImageSharp.gatsbyImageData)}
      url={location?.href}
      {...(seo as SEOProps)}
    />
    <DefaultLayout
      brand={{ color: "black", ...brand }}
      header={{
        color: "secondary",
        isFixed: true,
        right: <NewsHeaderMenu {...header?.menu} />,
        ...header,
      }}
      location={location}
      main={main}
      {...(layout as DefaultLayoutProps)}
    >
      <>
        {showHeader && (
          <NewsHero
            {...(hero as NewsHeroProps)}
            caption={{ heading: title, ...hero?.caption }}
            media={{ image, ...hero?.media }}
          />
        )}
        {body && (
          <MDX
            is="content"
            color="primary"
            className="z-10"
            {...mdx}
            body={body}
          />
        )}
        {showAuthor && <p>author of post goes here</p>}
        {showRecommended && <RecommendedNews next={next} prev={prev} />}
        {showFooter && <Contact location={location} {...contact} />}
      </>
    </DefaultLayout>
  </>
);

export const newsBySlugQuery = graphql`
  query NewsBySlug($slug: String!) {
    news: mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      fields {
        slug
      }
      frontmatter {
        author
        category
        dateCreated(formatString: "MMMM DD, YYYY")
        dateUpdated(formatString: "MMMM DD, YYYY")
        description
        image {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              layout: FULL_WIDTH
              placeholder: BLURRED
            )
          }
        }
        tags
        title
      }
    }
  }
`;

export default NewsTemplate;
